import React from "react";

const AboutPage = props => {
  return (
    <>
      <div className="container">
        <h1>About Page</h1>
        <div>
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Vero, dolorum in. Accusamus quia nesciunt autem mollitia animi nobis, maiores dolorem quisquam, voluptatem aspernatur est distinctio.
        </div>
        <div>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eius, tenetur.
        </div>
        <h3>Lorem ipsum dolor sit amet.</h3>
        <div>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. A saepe iste voluptatem mollitia quo rerum obcaecati. Eos earum in quis reprehenderit quos vitae, optio dolorum vel corporis. Voluptas placeat voluptates, nam aliquid hic pariatur modi, tempora vitae omnis reiciendis fugiat assumenda? Dolor quo reprehenderit exercitationem omnis temporibus necessitatibus magnam explicabo ipsam error, culpa obcaecati doloremque at minima? Numquam natus vitae ratione molestias eos aliquid esse repellendus labore aut corrupti officia excepturi voluptate perspiciatis eum, illum nulla voluptas eveniet quod iusto quo a vel! Labore, nesciunt eaque quia consequatur odit, maiores nostrum facere natus atque a doloribus! Animi consequatur quos commodi ratione in aspernatur, quia aliquid porro architecto delectus minima velit 
          
          <div>
            <h4>Lorem ipsum dolor sit.</h4>
          obcaecati tempore amet omnis, aperiam libero maiores. Incidunt tempora facere ea, assumenda repellendus dolorum id culpa dolor! Vero distinctio cum reprehenderit aliquam odit temporibus laboriosam sed nulla, doloribus, ipsa magni veritatis, repudiandae aliquid amet eos! Ab sapiente debitis iste facere error minima quo ducimus enim asperiores aut nobis recusandae eaque voluptas maiores neque fugit dolorem ipsum, incidunt repudiandae, ea quae quaerat autem temporibus. Tenetur, error? Cupiditate id debitis atque et minus saepe repellat reprehenderit, pariatur quia recusandae sapiente, nihil voluptates labore sint! Rem excepturi dolorum corrupti suscipit, possimus itaque deleniti ducimus assumenda adipisci aspernatur consequatur debitis molestiae magnam animi nulla quasi ratione dolor alias, explicabo eos sint, et beatae aliquid consectetur. Voluptates fugit illo, aperiam autem qui optio deleniti inventore laboriosam quis dolores ut eos repellat dicta. Soluta voluptatum adipisci consectetur. Non tempore neque blanditiis veritatis odit numquam. Ducimus, tempora?  
          </div> 
        </div>
      </div>
    </>
  );
};

export default AboutPage;
