import React, { Component } from "react";

class Homepage extends Component {
  state = {};
  render() {
    return (
      <>
        <div className="container">
          <h3 className="center">Oromo Resource Center</h3>
          <div>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Deleniti atque distinctio nemo, asperiores laborum dolorem perferendis modi exercitationem! Porro repellendus, laborum expedita ad commodi reiciendis necessitatibus deleniti ipsam quod impedit sit dolore optio ipsa voluptate dolor. Deserunt accusantium illo vero dignissimos aspernatur ex nostrum optio reiciendis commodi numquam explicabo fugiat aliquam nesciunt neque, amet voluptatibus quisquam quaerat vel, quia enim autem repellat? Rerum neque iste ad. Reiciendis rerum accusantium sapiente, aliquam nesciunt tempora provident consectetur saepe quibusdam animi nihil quaerat quis nemo inventore possimus labore eius error facilis dolorum rem reprehenderit ullam! Earum nulla pariatur, dicta saepe exercitationem laborum doloribus porro enim omnis, aperiam quis, modi facere ut consequuntur adipisci amet optio eius doloremque odit tempora quidem a quisquam. Veniam perspiciatis animi vitae necessitatibus quae, ipsum obcaecati, officia quisquam exercitationem quaerat voluptatibus aspernatur maxime ex. Ad debitis a nostrum eligendi earum distinctio blanditiis iusto quis sunt, veritatis minima, nemo iste doloremque, quaerat sapiente nam quibusdam eos at ab! Aut expedita dolore sed temporibus soluta facilis tempora voluptas amet nihil, eum dolores a, veritatis dolorum. Aliquid quo sed nobis eum quod ad placeat ea incidunt debitis at, qui inventore commodi! Labore, quisquam laborum facilis adipisci consectetur repellendus corrupti non porro animi.
          </div>
          <ul>
            <li>Lorem ipsum dolor sit amet consectetur adipisicing.</li>
            <li>Lorem ipsum dolor sit amet consectetur adipisicing.</li>
            <li>Lorem ipsum dolor sit amet consectetur adipisicing.</li>
            <li>Lorem ipsum dolor sit amet consectetur adipisicing.</li>
            <li>Lorem ipsum dolor sit amet consectetur adipisicing.</li>
          </ul>
          <h4>Lorem ipsum dolor sit.</h4>
          <div>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ab illo alias delectus laudantium sequi error officia fuga doloribus. Cum, voluptatem.
          </div>
          <h5>Lorem ipsum dolor sit.</h5>
          <div>Lorem ipsum dolor, sit amet consectetur adipisicing elit. At est numquam nemo dolores. Aperiam ab fugiat, temporibus aspernatur facilis voluptatem ut in assumenda vel provident explicabo mollitia corporis reprehenderit hic, qui dicta deserunt non atque nam debitis corrupti voluptates et! Numquam tempore, commodi consequatur doloremque error, ratione alias omnis minima sapiente consequuntur ducimus nulla. Reiciendis beatae enim quasi tempora quod fugiat debitis, quis laudantium qui! Distinctio eaque, iusto ex obcaecati reiciendis quisquam iure voluptatibus illum ipsa corporis dolorum quis facilis esse! Vel, nostrum natus. Odio facilis eius dignissimos quos voluptatibus omnis dicta laudantium adipisci repellendus. Illo minus reiciendis laborum.</div>
        </div>
      </>
    );
  }
}

export default Homepage;
